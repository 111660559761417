<template>
  <v-container fluid class="public-profile">
    <PublicProfile :profileId="$route.params.id"/>
    <v-row class="branding">
      <span>Powered by
        <a
        class="brand-details"
        :href="url('www.blendedsense.com')"
        target="blank">Blended Sense</a>
      </span>
    </v-row>
  </v-container>
</template>

<script>
import PublicProfile from '@/components/profile/creative/ProfileView';
import { generateUrl } from '@/helpers';

export default {
  name: 'CreativePublicProfile',
  components: { PublicProfile },
  methods: {
    url(link) {
      return generateUrl(link);
    },
  },
};

</script>

<style lang="scss" scoped>
.public-profile {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .branding {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 20px 10px;
    .brand-details {
      font-family: $fontFamily1;
      font-size: 14px;
      color: $charcoalBlack;
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 600px) {
  .branding {
    padding-bottom: 8px !important;
  }
}
@media only screen and (max-width: 960px) and (orientation: landscape) {
  .branding {
    padding-bottom: 8px !important;
  }
}
</style>
